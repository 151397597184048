import React, { useState } from "react";
import InstallationButtons from "./InstallationButtons";
import CustTaskDetails from "./CusTaskDetails";
import SiteInfo from "../../Site/SiteSingleViewComponents/SiteInfo";
import InstallationInfo from "./InstallationInfo";
import CommentList from "./CommentList";
import {
    Grid
} from "@material-ui/core";
import UploadFiles from "../../../Components/Modals/UploadFiles";
import { downloadFile } from "../../../Functions/fileDownload";
import SiteImageModal from "../../../Components/Modals/SiteImageModal";

const InstallationDetails = (props) => {
    const {
        classes,
        userPermissions,
        isAuthenticated,
        customer,
        data,
        tiny,
        isSm,
        language,
        site,
        newComment,
        setUploadModal,
        uploadModalStatus,
        setFile,
        userId,
        openActionModal,
        openAsignToMeModal,
        openActionCancelModal,
        openResetAlocationModal,
        openUpdateReasonModal,
        sendingComment,
        commentText,
        handleCommentTextChange,
        showCommentBox,
        handleCommentBoxShow,
        openCloseWithImage,
        openProgressModal,
        openServicesModal
    } = props;

    return (
        <>
            <UploadFiles
                open={uploadModalStatus}
                close={() => setUploadModal(false)}
                execute={(base64File) => setFile(base64File)}
                language={props.language}
            />
            <CustTaskDetails
                customer={customer}
                language={language}
                tiny={tiny}
                userPermissions={userPermissions}
                isAuthenticated={isAuthenticated}
            />
            <SiteInfo
                data={site}
                language={language}
                userPermissions={userPermissions}
                isAuthenticated={isAuthenticated}
            />
            <SiteImageModal siteId={site.OBJID} />
            <InstallationInfo
                classes={classes}
                data={data.installations}
                language={language}
                tiny={tiny}
                isSm={isSm}
            />
            <Grid
                item
                xs={12}
                container
                direction="row"
                spacing={4}
                className={classes.buttonGroup}
            >
                <InstallationButtons
                    language={language}
                    tiny={tiny}
                    installation={data.installations}
                    userPermissions={userPermissions}
                    isAuthenticated={isAuthenticated}
                    userId={userId}
                    openAction={() => openActionModal()}
                    openAsignToMeModal={openAsignToMeModal}
                    openBilling={() => alert("setBillingModalStatus")}
                    openBillingModal={() => alert("setBillingModalStatus")}
                    openActionCancelModal={openActionCancelModal}
                    openResetAlocationModal={openResetAlocationModal}
                    openUpdateReasonModal={openUpdateReasonModal}
                    openCloseWithImage={openCloseWithImage}
                    openProgressModal={openProgressModal}
                    openServicesModal={openServicesModal}
                />
            </Grid>
            <CommentList
                downloadFile={downloadFile}
                classes={classes}
                task={data}
                data={data && data.history}
                language={language}
                tiny={tiny}
                newComment={newComment}
                openFileUploadModal={() => setUploadModal(true)}
                sendingComment={sendingComment}
                commentText={commentText}
                showCommentBox={showCommentBox}
                handleCommentTextChange={handleCommentTextChange}
                handleCommentBoxShow={handleCommentBoxShow}
            />
        </>
    )
};

export default InstallationDetails;
