import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomepageIcon from "@material-ui/icons/HomeRounded";
import {
    PeopleAlt,
    LocationCity,
    NewReleases,
    EqualizerRounded,
    HomeWorkRounded,
    Build,
    NotificationsActiveRounded,
    ReceiptRounded,
    Email,
    MarkunreadMailbox,
    FileCopy,
    PermContactCalendar,
    Assignment,
    AccountTree,
    Warning,
    SettingsInputComponent,
    DirectionsCar
} from "@material-ui/icons";
import UsersIcon from "@material-ui/icons/GroupRounded";
import ExecutorsIcon from "@material-ui/icons/BusinessRounded";
import AreasIcon from "@material-ui/icons/PinDropRounded";
import RoleIcon from "@material-ui/icons/AccountTreeRounded";
import DepartmentsIcon from "@material-ui/icons/CategoryRounded";
import Can from "../Components/Misc/Can";
import * as lang from "./strings";

export default function MainList(props) {
  return (
    <div>
      <Link
        active="true"
        to={"/home"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <HomepageIcon />
          </ListItemIcon>
          <ListItemText primary={lang.HOME[props.language]} />
        </ListItem>
      </Link>

      <Can neededPermissions={{ parent: "CUSTOMERS", name: "VIEW_CUSTOMER" }}>
        <Link
          active="true"
          to={"/customers"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <PeopleAlt />
            </ListItemIcon>
            <ListItemText primary={lang.CLIENTS[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can neededPermissions={{ parent: "SITE", name: "VIEW_SITE" }}>
        <Link
          active="true"
          to={"/sites"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <LocationCity />
            </ListItemIcon>
            <ListItemText primary={lang.OBJECTIVES[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{
          parent: "INTERVENTIONS",
          name: "ACTION_INTERVENTION_DISPATCHER",
        }}
      >
        <Link
          active="true"
          to={"/interventions"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <NotificationsActiveRounded />
            </ListItemIcon>
            <ListItemText primary={lang.ALARMS[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{
          parent: "INTERVENTIONS",
          name: "ACTION_INTERVENTION_BASIC",
        }}
      >
        <Link
          active="true"
          to={"/interventions"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <NotificationsActiveRounded />
            </ListItemIcon>
            <ListItemText primary={lang.ALARMS[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{ parent: "DASHBOARD", name: "TECHNIC_MANAGEMENT" }}
      >
        <Link
          active="true"
          to={"/technic_management"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <PermContactCalendar />
            </ListItemIcon>
            <ListItemText primary={lang.TECHNIC_MANAGEMENT[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can neededPermissions={{ parent: "TASKS", name: "VIEW_TASK" }}>
        <Link
          active="true"
          to={"/tasks"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <NewReleases />
            </ListItemIcon>
            <ListItemText primary={lang.TASKS_REPAIR[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{
          parent: "INSTALLATION",
          name: "VIEW_INSTALLATION",
        }}
      >
        <Link
          active="false"
          to={"/installation"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <SettingsInputComponent />
            </ListItemIcon>
            <ListItemText primary={lang.TASK_INSTALLATION[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{
          parent: "MAINTENANCE",
          name: "VIEW_MAINTENANCE",
        }}
      >
        <Link
          active="true"
          to={"/maintenance"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            <ListItemText primary={lang.TASK_MAINTENANCES[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{
          parent: "PROJECTS",
          name: "VIEW_PROJECTS",
        }}
      >
        <Link
          active="true"
          to={"/project"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
            <ListItemText primary={lang.PROJECTS[props.language]} />
          </ListItem>
        </Link>
      </Can>
      {/* @TODO ADD THE PERMISSIONS */}

      <Can
        neededPermissions={{
          parent: "RISC_EVALUATIONS",
          name: "ADD_RISC_EVALUATIONS",
        }}
      >
        <Link
          active="false"
          to={"/risc_evaluation"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <Warning />
            </ListItemIcon>
            <ListItemText primary={lang.RISC_EVALUATIONS[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{ parent: "TASKS", name: "VIEW_TASK_BILL_HISTORY" }}
      >
        <Link
          active="true"
          to={"/bill_history"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <MarkunreadMailbox />
            </ListItemIcon>
            <ListItemText primary={lang.TASK_BILL_HISTORY[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{
          parent: "DASHBOARD",
          name: "AREA_MANAGMENT",
        }}
      >
        <Link
          active="true"
          to="/area_management"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary={lang.AREA_MANAGEMENT[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can neededPermissions={{ parent: "REPORTS", name: "VIEW_REPORTS_PAGE" }}>
        <Link
          active="true"
          to={"/reports"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <EqualizerRounded />
            </ListItemIcon>
            <ListItemText primary={lang.REPORTS[props.language]} />
          </ListItem>
        </Link>
      </Can>


      <Can
        neededPermissions={{
          parent: "TECHNICAL_TEAM",
          name: "VIEW_AND_EDIT_TECHNICAL_SCORE_HISTORY",
        }}
      >
        <Link
          active="true"
          to={"/technical-user-score"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <FileCopy />
            </ListItemIcon>
            <ListItemText primary={lang.TECHNICAL_USER_HISTORY[props.language]} />
          </ListItem>
        </Link>
      </Can>
      <Can
        neededPermissions={{
          parent: "TEMPLATE_FILE",
          name: "VIEW_TEMPLATE_FILE",
        }}
      >
        <Link
          active="true"
          to={"/templates"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <FileCopy />
            </ListItemIcon>
            <ListItemText primary={lang.TEMPLATE_FILES[props.language]} />
          </ListItem>
        </Link>
      </Can>

      <Can
        neededPermissions={{ parent: "ACCOUNTING", name: "ACCOUNTING_ACCESS" }}
      >
        <Link
          active="true"
          to={"/accounting"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ReceiptRounded />
            </ListItemIcon>
            <ListItemText primary={lang.ACCOUNTING[props.language]} />
          </ListItem>
        </Link>
      </Can>

    </div>
  );
}

export function AdminList(props) {
  return (
    <div>
      <Link
        active="true"
        to={"/home"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <HomepageIcon />
          </ListItemIcon>
          <ListItemText primary={lang.HOME[props.language]} />
        </ListItem>
      </Link>
      <Link
        active="true"
        to={"/dashboard/users"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          <ListItemText primary={lang.USER_TABLE[props.language]} />
        </ListItem>
      </Link>
      <Link
        active="true"
        to={"/dashboard/executors"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <ExecutorsIcon />
          </ListItemIcon>
          <ListItemText primary={lang.EXEC_TABLE[props.language]} />
        </ListItem>
      </Link>
      <Link
        active="true"
        to={"/dashboard/roles"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <RoleIcon />
          </ListItemIcon>
          <ListItemText primary={lang.ROLE_TABLE[props.language]} />
        </ListItem>
      </Link>
      <Link
        active="true"
        to={"/dashboard/areas"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <AreasIcon />
          </ListItemIcon>
          <ListItemText primary={lang.AREA_TABLE[props.language]} />
        </ListItem>
      </Link>
      <Link
        active="true"
        to={"/dashboard/departments"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <DepartmentsIcon />
          </ListItemIcon>
          <ListItemText primary={lang.DEPARTMENT_TABLE[props.language]} />
        </ListItem>
      </Link>
      <Link
        active="true"
        to={"/dashboard/site_types"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <HomeWorkRounded />
          </ListItemIcon>
          <ListItemText primary={lang.SITE_TYPE_TABLE[props.language]} />
        </ListItem>
      </Link>
      <Link
        active="true"
        to={"/dashboard/mailing_list"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItem button>
          <ListItemIcon>
            <Email />
          </ListItemIcon>
          <ListItemText primary={lang.MAILING_LISTS[props.language]} />
        </ListItem>
      </Link>
        <Link
            active="true"
            to={"/dashboard/technical-teams-management"}
            style={{ textDecoration: "none", color: "inherit" }}
        >
            <ListItem button>
                <ListItemIcon>
                    <DirectionsCar/>
                </ListItemIcon>
                <ListItemText primary={lang.TECHNICAL_TEAMS[props.language]} />
            </ListItem>
        </Link>
    </div>
  );
}

