import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionCreators from "../actions/actions";
import {useEffect} from "react";

const GPS_ERRORS = {
    1: "Poziția GPS este blocată! Pentru a putea să execuți acțiuni pe tickete activează poziția GPS pentru crm.rpgsuceava.ro!",
    2: "Poziția GPS nu se poate fi determinată!",
    3: "Nu s-a putut citi poziția GPS! Te rugăm să reîncarci pagina.",
};

/**
 * Request the GPS position and stores it in the REDUX global store
 *
 * @returns {JSX.Element}
 * @constructor
 */
const RequestGPS = (props) => {

    const displayPosition = (position) => {
        props.actions.onGeolocationUpdate({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            error: false,
        });

        console.log(`Tracking (${Math.floor(position.coords.accuracy) + 1}m accuracy)...`);
    };

    const displayError = (error) => {
        props.actions.onGeolocationUpdate({
            latitude: null,
            longitude: null,
            accuracy: null,
            error: GPS_ERRORS[error.code],
        });
        alert(GPS_ERRORS[error.code]);
    };

    useEffect(() => {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            if (result.state !== "granted") {
                if (navigator.geolocation) {
                    navigator.geolocation.watchPosition(
                      displayPosition,
                      displayError,
                      {
                          enableHighAccuracy: true,
                      }
                    );
                }
            }
        });
    }, []);

    return <></>
}

const mapStateToProps = () => {};
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(RequestGPS);
