import {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import axios from "axios";

import CustomModal from '../../../Components/Modals/CustomModal';
import * as requestHeaders from '../../../Constants/requestHeaders';

const MoveConfirmation = (props) => {
    const {
        open,
        onClose,
        data,
        language,
        refetch,
        team
    } = props;

    const [todayTeams, setTodayTeams] = useState([]);

    const [selectedTeam, setSelectedTeam] = useState(null);

    const fetch = async () => {
        try {
            const res = await axios.get("/api/currently-existing-teams", requestHeaders.getGetHeader())
            setTodayTeams(res.data.filter(x => x.OBJID !== team.OBJID));
        } catch (e) {
            console.error(e);
        }
    }

    const move = async () => {
        try {
            await axios.put(
                "/api/technical/team/management",
                {
                    userId: data.OBJID,
                    newTeamId: selectedTeam.OBJID,
                    oldTeamId: team.OBJID,
                },
                requestHeaders.getPostHeader()
            );
            if (refetch) refetch().then(() => onClose());
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetch().then();
    }, []);

    return (<CustomModal
        fullWidth
        open={open}
        close={() => onClose()}
        title={"Mutare utilizator in echipaj existente"}
        content={
            <div>
                <FormControl style={{width: "100%"}}>
                    <InputLabel
                        style={{paddingLeft: "10px"}}
                        id="standard-label"
                    >
                        Selecteaza noul echipaj
                    </InputLabel>
                    <Select
                        fullWidth
                        labelId="standard-label"
                        variant="outlined"
                        onChange={(event) => setSelectedTeam(event.target.value)}
                        value={selectedTeam}
                    >
                        {todayTeams.map((team, index) => (
                            <MenuItem
                                value={team}
                                key={`today_teams_${index}`}
                            >
                                {team.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        }
        execute={() => move()}
        language={language}
        validate={selectedTeam !== null}
    />)
}

export default MoveConfirmation;