import React, {useState} from "react";
import CustomModal from "../../../../Components/Modals/CustomModal";
import ImageUploadField from "../../../../Components/ImageUploadField";
import * as lang from "../../../../Constants/strings";
import {CircularProgress, Grid} from "@material-ui/core";
import {getPostHeader} from "../../../../Constants/requestHeaders";
import axios from "axios";

import {bindActionCreators} from "redux";
import * as actionCreators from "../../../../actions/actions";
import {connect} from "react-redux";

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
}

const AddSiteImage = (props) => {
    const {
        siteId,
        open,
        close,
        language
    } = props;

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const saveFile = async () => {
        setLoading(true);

        try {
            const blob = dataURItoBlob(file);
            const fd = new FormData();

            fd.append("file", blob);
            fd.append("siteId", siteId);

            await axios.post('/api/site-file', fd, getPostHeader());
            props.actions.onNotificationOpen('success', lang.DATA_UPDATE_SUCCESS, 6000);
            close();
        } catch (e) {
            setFile(false);
            props.actions.onNotificationOpen(
                "warning",
                lang.ERROR_OCCURED,
                6000
            );
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <CustomModal
            fullWidth
            open={open}
            title={lang.ADD_SITE_IMAGE_MODAL_TITLE[language]}
            content={
                <Grid container spacing={2} style={{position: "relative", height: "100%"}}>
                    {loading &&
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                background: "#ffffff50",
                                position: "absolute",
                                zIndex: 99,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress/>
                        </div>
                    }
                    <ImageUploadField
                        language={language}
                        loading={false}
                        onImageLoad={(img) => setFile(img)}
                        maxWidth={550}
                    />
                </Grid>
            }
            close={close}
            execute={saveFile}
            language={language}
            validate={!!file}
        />
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch),
});

const mapStateToProps = (state) => ({
    language: state.config.language,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSiteImage);