import { useState, useEffect } from "react";
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    Button,
    TextField,
    Typography
} from "@material-ui/core";
import axios from "axios";

import Modal from "./Modals/CustomModal";
import * as lang from "../Constants/strings";
import * as reqHeaders from "../Constants/requestHeaders";

const RegisterServicesOnTask = (props) => {
    const {
        open,
        onClose,
        language,
        taskId
    } = props;

    const [selectedServices, setSelectedServices] = useState([]);
    const [services, setServices] = useState([]);
    const [currentlySelectedService, setCurrentlySelectedService] = useState(null);

    const save = async () => {
        const data = selectedServices.map(x => ({ id: x.id, quantity: x.quantity, name: x.name }));

        try {
            await axios.post('/api/register/task/service', { services: data, taskId }, reqHeaders.getPostHeader());
            onClose();
        } catch (e) {
            console.error(e);
        }
    }

    const getServices = async () => {
        try {
            const res = await axios.get("/api/services", reqHeaders.getGetHeader());
            if(res?.data?.services) {
                setServices(res.data.services);
            } else {
                setServices([]);
                console.warn("The services where not returned!");
            }
        } catch (e) {
            console.error(e);
        }
    }

    const onSelectService = () => {
        const exist = selectedServices.findIndex(x => x.id === currentlySelectedService?.id) !== -1;

        if (!exist) {
            const services = [...selectedServices];
            let tmp = {
                ...currentlySelectedService,
                quantity: 1
            }
            services.push(tmp);
            setSelectedServices(services);
        }

        setCurrentlySelectedService(null);
    }

    const remove = (index) => {
        let tmp = [ ...selectedServices];
        tmp.splice(index, 1);
        setSelectedServices(tmp);
    }

    const setServiceQuantity = (value, index) => {
        const newValue = Number(value);
        let tmp = [...selectedServices];

        if(!isNaN(newValue) && value !== "") {
            tmp[index].quantity = Math.max(value, 1);
        } else {
            tmp[index].quantity = value;
        }

        setSelectedServices(tmp);
    }

    useEffect(() => {
        if(open) {
            getServices().then(() => {});
            setSelectedServices([]);
            setCurrentlySelectedService(null);
        }
    }, [open]);

    return (
        <Modal
            fullScreen
            fullWidth
            form={false}
            open={open}
            close={() => onClose()}
            title={lang.REGISTER_SERVICES_TO_TASK[language]}
            content={<Grid container spacing={2} justifyContent={"center"} alignItems="center">
                <Grid item xs={12} md={10}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                    >
                        <InputLabel id="select-service">Servicii</InputLabel>
                        <Select
                            lable={lang.REGISTER_SERVICES_TO_TASK[language]}
                            variant="outlined"
                            inputProps={{id: "select-service"}}
                            fullWidth
                            value={currentlySelectedService}
                            onChange={(e) => setCurrentlySelectedService(e.target.value)}
                        >
                            {
                                services.map(x => <MenuItem key={`service_${x.id}`} value={x}>{x.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => onSelectService()}
                    >
                        {lang.ADD_SERVICE[language]}
                    </Button>
                </Grid>
                {
                    selectedServices.length > 0 &&
                    <Typography
                        variant="h4"
                        style={{ marginTop: "2rem" }}
                    >
                        Services
                    </Typography>
                }
                <Grid
                    container
                    spacing={2}
                    style={{ margin: "10px", boxShadow: "0px 0px 0px 1px #ffffff40" }}
                    justifyContent="center"
                    alignItems="center"
                >
                    {selectedServices.map((x, index) => <>
                        <Grid item xs={12} md={8}>
                            <Typography
                                style={{ marginLeft: "1rem" }}
                                variant="h6"
                            >
                                {x.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                label="Cantitate (h)"
                                type="number"
                                value={x.quantity}
                                onChange={(e) => setServiceQuantity(e.target.value, index)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={1}></Grid>
                        <Grid item xs={12} md={1}>
                            <Button
                                fullWidth
                                onClick={() => remove(index)}
                                variant="contained"
                                color="secondary"
                            >
                                Stergere
                            </Button>
                        </Grid>
                    </>)}
                </Grid>
            </Grid>}
            execute={() => save()}
            language={language}
            validate={selectedServices.length > 0 && selectedServices.findIndex(x => Number(x.quantity) === 0) === -1}
        />
    )
}

export default RegisterServicesOnTask;
