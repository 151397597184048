import {useState} from "react";
import {Button, Divider, Grid, Typography} from "@material-ui/core";
import DeleteConfirmation from "./DeleteConfirmation";
import MoveConfirmation from "./MoveConfirmation";
import AddConfirmation from "./AddConfirmation";

const Team = (props) => {
    const {
        teamName,
        team,
        users,
        language,
        refetch
    } = props;

    const [toDelete, setToDelete] = useState(null);
    const [toMove, setToMove] = useState(null);
    const [toAdd, setToAdd] = useState(null);

    return (
        <Grid
            container
            spacing={2}
            style={{
                marginTop: "50px",
                padding: "10px",
                border: "1px solid #9f9f9f80",
                borderRadius: "10px",
                backgroundColor: "#9f9f9f20"
            }}
        >
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    style={{padding: "5px"}}
                >
                    <Typography variant="h4">{teamName}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setToAdd(team)}
                    >
                        + Adauga utilizator in echipa
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                users.map((x, index) => (
                    <Grid item xs={12} key={`user-teamName-${index}`}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                                border: "1px solid #9f9f9f80",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                borderRadius: "10px",
                                marginTop: "10px"
                            }}
                        >
                            <Grid item xs={6} md={10}>
                                <Typography variant="h6" color="inherit">
                                    {x.USERNAME.toUpperCase()} {`(${x.LAST_NAME} ${x.FIRS_NAME})`.toUpperCase()}
                                </Typography>
                            </Grid>

                            <Grid item xs={3} md={1}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setToDelete(x)}
                                >
                                    Sterge din echipa
                                </Button>
                            </Grid>

                            <Grid item xs={3} md={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setToMove(x)}
                                >
                                    Muta utilizator
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>))
            }
            <DeleteConfirmation
                open={!!toDelete}
                onClose={() => setToDelete(undefined)}
                data={toDelete}
                teamName={teamName}
                language={language}
                refetch={refetch}
                team={team}
            />
            <MoveConfirmation
                open={!!toMove}
                data={toMove}
                onClose={() => setToMove(undefined)}
                language={language}
                refetch={refetch}
                team={team}
            />
            <AddConfirmation
                open={!!toAdd}
                onClose={() => setToAdd(undefined)}
                data={toAdd}
                teamName={teamName}
                language={language}
                refetch={refetch}
            />
        </Grid>
    )
}

export default Team;