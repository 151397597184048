import React, {useEffect, useState} from 'react';
import { makeStyles, Grid, Paper } from '@material-ui/core';
import {  Receipt,  AssignmentIndRounded, PauseRounded, ForwardRounded, CancelRounded, ShortText } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';

import GroupButtonSet from '../../../Components/Misc/GroupButtonSet';
import * as lang from '../../../Constants/strings';
import { checkPermissions } from '../../../Functions/permissionHide';
import * as requestHeaders from '../../../Constants/requestHeaders';

const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(2),
    },
    float: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 99,
    },
    menuIcon: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-0.5),
    },
    paperClear: {
        padding: theme.spacing(2),
        height: '100%',
    },
}));

export default function TaskButtons(props) {
    const classes = useStyles();

    const [hasServiceOrItemsRegister, setHasServiceOrItemsRegister] = useState(false);

    const { language, tiny, task } = props;

    const checkIfTaskHasServicesOrItems = async () => {
        try {
            const res = await axios.get('/api/task/has-service-or-basket', {
                ...requestHeaders.getGetHeader(),
                params: {
                    id: task.data.task.OBJID
                }
            });

            setHasServiceOrItemsRegister(res.data.hasServiceOrItems);
        } catch (e) {
           console.log(e);
        }
    }

    // Will filter and validate the close action
    const validationForCloseAction = (statusId) => {
        if (statusId !== 6) {
            props.openAction(statusId, false);
        } else {
            // If the current status is 6 (STARTED) then we must have a service or items register on the task
            // don't allow the close modal to be open if that is not the case
            if(!hasServiceOrItemsRegister) {
                alert("Pentru a deschide modalul de inchidere a task-ului trebuie sa inregistrezi cel putin un echipament montat sau un serviciu prestat!");
                return;
            }

            props.openAction(statusId, false);
        }
    }

    useEffect(() => {
        if(["STARTED"].indexOf(task.data.task.STATUS) !== -1 && task.userId === task.data.task.OWNER_CURR) {
            checkIfTaskHasServicesOrItems();
        }
    }, [task]);

    return (
        <React.Fragment>
            {task.loading
                ?
                <SkeletonTaskButtons classes={classes} />
                :
                <Grid item container direction="row" xs={12} lg={12} style={{ paddingRight: '0px' }}>
                    {task && task.data && task.data.task &&
                        <GroupButtonSet
                            tiny={tiny}
                            language={language}
                            style={{
                                size: "large",
                                color: "default",
                                variant: "contained",
                                disabled: false,
                            }}
                            list={[
                                {
                                    primary: task.userId === task.data.task.OWNER_CURR,
                                    text: lang.NEXT_STATUS(task.data.task.STATUS)[language],
                                    style: {
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1 ||
                                                  ["STARTED"].indexOf(task.data.task.STATUS) !== -1 && props.taskData.CONTRACT_OBJID === null,
                                    },
                                    icon: <ForwardRounded />,
                                    render: task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }),
                                    action: () => validationForCloseAction(task.data.task.STATUS_ID),
                                },
                                {
                                    text: lang.LOAD_IMAGE[language],
                                    icon: <PauseRounded />,
                                    style: {
                                        disabled: props.taskData.CONTRACT_OBJID === null,
                                    },
                                    render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1 && task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }) && task.data.task.FK2BILL !== null,
                                    action: () => {
                                        if(!hasServiceOrItemsRegister) {
                                            alert("Pentru a deschide modalul de inchidere a task-ului trebuie sa inregistrezi cel putin un echipament montat sau un serviciu prestat!");
                                            return;
                                        }

                                        props.openAction("LOAD_IMAGE", 8);
                                    },
                                },
                                {
                                    text: lang.SELECT_CONTRACT[language],
                                    style: {
                                        color: "secondary",
                                        disabled: props.taskData.CONTRACT_OBJID !== null,
                                    },
                                    icon: <Receipt />,
                                    render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1 &&
                                        task.userId === task.data.task.OWNER_CURR &&
                                        checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }),
                                    action: () => props.openContract()
                                },
                                {
                                    primary: task.userId !== task.data.task.OWNER_CURR,
                                    text: lang.ASSIGN_TO_ME[language],
                                    style: {
                                        // variant: 'contained',
                                        disabled: ["OPEN", "ON_HOLD"].indexOf(task.data.task.STATUS) === -1,
                                    },
                                    icon: <AssignmentIndRounded />,
                                    leftIcon: <AssignmentIndRounded fontSize="small" className={classes.leftIcon} />,
                                    render: task.userId !== task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }),
                                    action: () => props.openAction("ASSIGN", task.data.task.STATUS_ID),
                                },
                                {
                                    primary: task.userId !== task.data.task.OWNER_CURR,
                                    text: lang.CANCEL_ALOCATION[language],
                                    style: {
                                        // variant: 'contained',
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1 || ["ON_HOLD"].indexOf(task.data.task.STATUS) !== -1,
                                    },
                                    icon: <AssignmentIndRounded />,
                                    leftIcon: <AssignmentIndRounded fontSize="small" className={classes.leftIcon} />,
                                    render: ["CLOSED", "CANCELLED", "ON_HOLD"].indexOf(task.data.task.STATUS) === -1 &&
                                        ((task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" })) ||
                                            (checkPermissions(props.userPermissions, { parent: "TASKS", name: "SUPER_RESET_TASK" }))),
                                    action: () => props.openResetTask()
                                },
                                {
                                    text: lang.UPDATE_REASON[language],
                                    icon: <ShortText />,
                                    style: {
                                        color: "primary",
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1
                                    },
                                    render: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) === -1 && checkPermissions(props.userPermissions, { parent: "TASKS", name: "UPDATE_TASK_REASON" }),
                                    action: () => props.openUpdateReason(),
                                },
                                {
                                    text: lang.REGISTER_ITEMS_TO_TASK[language],
                                    icon: <ShortText />,
                                    style: {
                                        color: "primary",
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1
                                    },
                                    render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1 && task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TECHNICAL_TEAM", name: "EXTERNAL_INVENTORY" }),
                                    action: () => props.openRegisterProgress(),
                                },
                                {
                                    text: lang.REGISTER_SERVICES_TO_TASK[language],
                                    icon: <ShortText />,
                                    style: {
                                        color: "primary",
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1
                                    },
                                    render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1 && task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TECHNICAL_TEAM", name: "EXTERNAL_INVENTORY" }),
                                    action: () => props.openServicesProgress(),
                                },
                                {
                                    text: lang.CANCEL[language],
                                    style: {
                                        color: "secondary",
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1
                                    },
                                    icon: <CancelRounded />,
                                    rightIcon: <CancelRounded fontSize="small" className={classes.rightIcon} />,
                                    render: ["CANCELLED"].indexOf(task.data.task.STATUS) === -1 && checkPermissions(props.userPermissions, { parent: "TASKS", name: "CANCEL_TASK" }),
                                    action: () => props.openAction("CANCELLED", 7),
                                },
                            ]}
                        />
                    }
                </Grid>
            }
        </React.Fragment>
    );
}
export function SkeletonTaskButtons(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} md={8} style={{ paddingRight: '0px' }} >
            <Skeleton component={Paper} className={classes.paperClear}>
                <Grid container justify="center" alignItems="center" direction="column">
                    {/* <Skeleton width="15%" className={classes.title} /> */}
                </Grid>
            </Skeleton>
        </Grid>
    );
}