import { useState, useEffect } from "react";
import { CssBaseline, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Team from "./Components/Team";
import * as actionCreators from "../../actions/actions";
import * as requestHeaders from "../../Constants/requestHeaders";

const Page = (props) => {
    const { language } = props;
    const [teams, setTeams] = useState([]);

    const fetch = async () => {
        try {
            const res = await axios.get("/api/technical/team/management", requestHeaders.getGetHeader());
            setTeams(res.data);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetch().then(() => {});
    }, []);

    return (
            <Grid container style={{ paddingLeft: '30px', paddingRight: '30px', marginTop: "100px" }}>
                <CssBaseline/>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Echipaje tehnic - {moment(new Date()).format("DD-MM-YYYY")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        teams.map((x, index) => <Team
                            key={`team_${index}`}
                            team={x}
                            teamName={x.name}
                            users={x.users}
                            language={language}
                            refetch={() => fetch()}
                        />)
                    }
                </Grid>
            </Grid>
    )
}

const mapStateToProps = (state) => ({
    language: state.config.language,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Page);