import {useEffect, useState} from "react";
import axios from "axios";

import CustomModal from '../../../Components/Modals/CustomModal';
import * as requestHeaders from '../../../Constants/requestHeaders';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

const AddConfirmation = (props) => {
    const {
        open,
        onClose,
        teamName,
        data,
        refetch,
        language
    } = props;

    const [userWithNoTeams, setUserWithNoTeams] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const add = async () => {
        try {
            await axios.post(
                "/api/technical/team/management",
                {
                    userId: selectedUser.USER_ID,
                    newTeamId: data.OBJID
                },
                requestHeaders.getPostHeader()
            );
            refetch().then(() => onClose());
        } catch (e) {
            console.error(e);
        }
    }

    const fetch = async () => {
        try {
            const res = await axios.get("/api/team_users", requestHeaders.getGetHeader());
            setUserWithNoTeams(res.data);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if(open) fetch().then(() => {});
    }, [open]);

    return (
        <CustomModal
            fullWidth
            open={open}
            close={() => onClose()}
            title={"Adauga utilizator in echipajul: " + teamName}
            content={
                <div>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel
                            style={{paddingLeft: "10px"}}
                            id="standard-label"
                        >
                            Selecteaza utilizator
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="standard-label"
                            variant="outlined"
                            onChange={(event) => setSelectedUser(event.target.value)}
                            value={selectedUser}
                        >
                            {userWithNoTeams.map((user, index) => (
                                <MenuItem
                                    value={user}
                                    key={`today_teams_${index}`}
                                >
                                    {user.USERNAME}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            }
            execute={() => add()}
            language={language}
            validate={selectedUser !== null}
        />)
}

export default AddConfirmation;