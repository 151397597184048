import CustomModal from '../../../Components/Modals/CustomModal';
import {Typography} from "@material-ui/core";
import axios from "axios";
import {getDeleteHeader} from "../../../Constants/requestHeaders";

const DeleteConfirmation = (props) => {
    const {
        open,
        onClose,
        teamName,
        data,
        team,
        refetch,
        language
    } = props;

    const deleteUserFromTeam = async () => {
        try {
            await axios.delete(
                `/api/technical/team/management`,
                {
                    ...getDeleteHeader(),
                    params: {
                        userId: data.OBJID,
                        oldTeamId: team.OBJID,
                    }
                }
            );
            refetch().then(() => onClose());
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <CustomModal
            fullWidth
            open={open}
            close={() => onClose()}
            title={"Confirmare sterge utilizator din echipa: " + teamName}
            content={
                <div>
                    <Typography variant="body1">
                        Utilizatorul va fi ster din echipa {teamName}!
                    </Typography>
                </div>
            }
            execute={() => deleteUserFromTeam()}
            language={language}
            validate={true}
        />)
}

export default DeleteConfirmation;